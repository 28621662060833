import WhatsAppIcon from '../../assets/icons/whatsapp.svg'
import FacebookIcon from '../../assets/icons/facebook.svg'
import MailIcon from '../../assets/icons/mail.svg'
import FailureIcon from '../../assets/icons/circle-xmark-solid.svg'

const Failure = () => {
  return (
    <div
      id="slide-phone-consult-error"
      className="phone-consult-error slide slide--consult"
    >
      <div className="slide__sections slide__sections--center slide__sections--text-center-full">
        <div className="slide__section">
          <span className="form-submit-title">Uh oh!</span>
          <img
            src={FailureIcon}
            alt="success icon"
            className="form-submit-icon"
          />
          <br />
          <span>
            We encountered an issue when booking your free consultation!
          </span>
          <br />
          <span>Please reach out to me via social media.</span>
          <br />
          <span>Talk to you soon!</span>
          <br />
          <div className="social-links-wrapper">
            <a href="https://wa.me/14808153372" target="_blank">
              <img
                className="social-image"
                src={WhatsAppIcon}
                alt="whatsapp icon"
              />
            </a>
            <a href="http://m.me/carah.robenalt" target="_blank">
              <img
                className="social-image"
                src={FacebookIcon}
                alt="facebook icon"
              />
            </a>
            <a href="mailto:carahmollerup@gmail.com" target="_blank">
              <img className="social-image" src={MailIcon} alt="email icon" />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Failure

import fetchCalendarData from '../requests/fetchCalendarData'
import { useEffect, useState } from 'react'

const formatEvents = events => {
  const today = new Date()
  const dates = {}
  for (let i = 0; i < 28; i++) {
    dates[
      new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() + i
      ).getTime()
    ] = []
  }
  events.forEach(({ start, end }) => {
    const startDate = new Date(start.dateTime)
    const date = new Date(
      startDate.getFullYear(),
      startDate.getMonth(),
      startDate.getDate()
    )
    if (dates[date.getTime()]) {
      const startTime = new Date(start.dateTime).toLocaleTimeString()
      const startLen = startTime.length - 6
      const endTime = new Date(end.dateTime).toLocaleTimeString()
      const endLen = endTime.length - 6
      dates[date.getTime()].push({
        start: `${startTime.slice(0, startLen)} ${startTime.slice(
          startTime.length - 3,
          startTime.length
        )}`,
        end: `${endTime.slice(0, endLen)} ${endTime.slice(
          endTime.length - 3,
          endTime.length
        )}`
      })
    }
  })
  return Object.entries(dates)
}

const Availability = () => {
  const [data, setData] = useState(null)
  const [page, setPage] = useState(0)

  useEffect(() => {
    fetchCalendarData().then(response => {
      setData(formatEvents(response.data.events))
    })
  }, [])

  const navigate = direction =>
    direction === 'next' ? setPage(page + 1) : setPage(page - 1)

  const buildDay = (date, events, index) => {
    const dateString = new Date(parseInt(date)).toDateString()
    const day = dateString.slice(0, 3)
    const month = dateString.slice(4, 7)
    const dayNum = dateString.slice(8, 10)
    return (
      <div className={`day${index === 6 ? ' day__last' : ''}`} key={date}>
        <div className="day__header">
          <div className="header__text-wrapper">
            <div className="header__day">{day}</div>
            <div className="header__month">{month}</div>
            <div className="header__dayNum">{dayNum}</div>
          </div>
        </div>
        <div className="day__body">
          {events.length > 0 ? (
            events.map(({ start, end }) => {
              return (
                <div className="day__event">
                  <div className="day__text">{start}</div>
                  <div className="day__text day__text--small">-</div>
                  <div className="day__text">{end}</div>
                </div>
              )
            })
          ) : (
            <div className="day__event--unavailable">
              <div className="day__text day__text--unavailable">
                No availability
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }

  const buildCalendar = device => {
    return (
      <>
        {!data && (
          <div className="data-loading">
            <div>Loading</div>
            <div className="lds-ellipsis lds-ellipsis--black submit-show">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        )}
        {device === 'desktop' &&
          data &&
          data
            .slice(page * 7, page * 7 + 7)
            .map(([date, events], index) => buildDay(date, events, index))}
        {device === 'mobile' &&
          data &&
          data.map(([date, events], index) => buildDay(date, events, index))}
      </>
    )
  }

  return (
    <div id="slide-reschedule" className="reschedule slide">
      <div className="slide__header">Availability</div>
      <div className="slide__sections slide__sections--calendar">
        <div className="calendar">
          {data && (
            <div className="calendar__nav calendar__nav--prev">
              <button
                className="calendar__nav-button"
                onClick={() => navigate('prev')}
                disabled={page === 0}
              >
                ⇐
              </button>
            </div>
          )}
          <div className="calendar__days desktop-calendar">
            {buildCalendar('desktop')}
          </div>
          <div className="calendar__days mobile-calendar">
            {buildCalendar('mobile')}
          </div>
          {data && (
            <div className="calendar__nav calendar__nav--next">
              <button
                className="calendar__nav-button"
                onClick={() => navigate('next')}
                disabled={page === 3}
              >
                ⇒
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Availability

import Notice from './Notice'
import ClockIcon from '../assets/icons/clock.svg'
import MusicIcon from '../assets/icons/music-note.svg'
import PianoIcon from '../assets/icons/piano.svg'
import { Link } from 'react-router-dom'

const Pricing = () => {
  return (
    <div id="slide-pricing" className="pricing slide">
      {/* <!-- pricing info --> */}
      <div className="slide__header">Pricing</div>
      <Notice path="/home" />
      <div className="slide__sections align-center">
        <div className="slide__section">
          <span className="about-slide__header slide__text--medium slide__text--title-font">
            Lesson Rates and Payment
          </span>
          <div className="duration-wrapper">
            <img className="duration-image" src={ClockIcon} alt="clock" />
            <span className="slide__text slide__text--align slide__text--small">
              30, 45, or 60 minute lessons
            </span>
          </div>
          <div className="icon-set">
            {/* <!-- Music Note Icon and text --> */}
            <div className="icon-wrapper">
              <img
                className="icon-small"
                src={MusicIcon}
                alt="music note icon"
              />
              <div className="slide__texts">
                <span className="slide__text mobile">Voice Lessons</span>
                <span className="slide__text slide__text--small desktop">
                  Voice
                </span>
                <span className="slide__text slide__text--small desktop">
                  Lessons
                </span>
              </div>
            </div>
            {/* <!-- Piano Icon and text --> */}
            <div className="icon-wrapper">
              <img className="icon-small" src={PianoIcon} alt="piano icon" />
              <div className="slide__texts">
                <span className="slide__text mobile">Piano Lessons</span>
                <span className="slide__text slide__text--small desktop">
                  Piano
                </span>
                <span className="slide__text slide__text--small desktop">
                  Lessons
                </span>
              </div>
            </div>
          </div>
          <span className="about-slide__header about-slide__header--no-line slide__text--medium slide__text--title-font">
            How long should my lesson be?
          </span>
          <div className="slide__detail">
            <span className="slide__text slide__text--margin">
              Ultimately, it is entirely your choice for how long you would like
              your lessons to be! However, I do have some general
              recommendations.
            </span>
            <span className="slide__text slide__text--margin">
              <b>30-minute lessons:</b> Ideal for my younger, or beginner
              students. Also ideal for short attention spans, or those with busy
              schedules.
            </span>
            <span className="slide__text slide__text--margin">
              <b>45-minute lessons:</b> My top recommendation - 45-minute
              lessons offer more time to delve into topics and work more on
              technique. We will be able to get more accomplished in this time,
              as it won't feel as rushed.
            </span>
            <span className="slide__text slide__text--margin">
              <b>60-minute lessons:</b> Ideal for my students who want to take
              their skills to the next level. This also works if you would like
              to take piano and voice and do 30 minutes of each.
            </span>
          </div>
        </div>
        {/* <!-- pricing chart --> */}
        <div className="slide__center-wrapper column">
          <div className="slide__section slide__section--reset">
            <div className="pricing-chart">
              <span className="pricing-chart__header">Pricing Options</span>
              <div className="pricing-chart__price-wrapper">
                <div className="pricing-chart__section">
                  <div className="pricing-chart__lesson">
                    <div className="pricing-chart__details">
                      <span className="pricing-chart__title">30 Minutes</span>
                      <span className="pricing-chart__price">$30</span>
                    </div>
                    <div className="pricing-chart__line-right"></div>
                    <div className="pricing-chart__details">
                      <span className="pricing-chart__title">45 Minutes</span>
                      <span className="pricing-chart__price">$45</span>
                    </div>
                    <div className="pricing-chart__line-right"></div>
                    <div className="pricing-chart__details">
                      <span className="pricing-chart__title">60 Minutes</span>
                      <span className="pricing-chart__price">$60</span>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div className="slide__center-wrapper desktop">
                <Link to="/schedule">
                  <button className="schedule-button schedule-button--green">
                    Schedule Now
                  </button>
                </Link>
              </div>
              <div className="slide__center-wrapper mobile">
                <Link to="/schedule">
                  <button className="schedule-button schedule-button--green">
                    Schedule Now
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Pricing

import Headshot from '../assets/images/headshot.png'
import Studio3Logo from '../assets/logos/studio_3.png'
import VcsLogo from '../assets/logos/vcs.png'
import GcuLogo from '../assets/logos/gcu_small.png'
import ChandlerOperaLogo from '../assets/logos/chandler_opera.png'
import ZaoLogo from '../assets/logos/zao.png'
import MccLogo from '../assets/logos/mcc.png'
import GoodeTimeLogo from '../assets/logos/goode_time.png'
import MetLogo from '../assets/logos/met.png'

const Bio = () => {
  return (
    <div id="slide-bio" className="bio slide">
      <div className="slide__sections slide__sections--center slide__sections--no-header">
        <div className="slide__section">
          <span className="about-slide__header about-slide__header slide__text--medium slide__text--title-font">
            Carah Mollerup - Bio
          </span>
          <div className="slide__detail slide__text--bio slide__text--justify">
            <div className="bio-img-wrap">
              <span className="slide__text slide__text--margin-all">
                Carah Mollerup graduated from Grand Canyon University in 2018
                with her degree in Music with an Emphasis in the Voice. Since
                then, she has been teaching and performing around the valley.
                After graduating, she spent three years at Valley Christian
                Elementary, where she taught choir, worship, theatre, and art to
                5th/6th students. Since she graduated, she has also been working
                for Studio 3 Performing Arts Academy, where she has taught group
                and private classes in voice, musical theatre, and acting for
                students from preschool to high school. Recently, she made the
                transition from working at a school during the day to opening
                her home studio, since what she loves most is helping students
                one on one to further their individual goals.
              </span>

              <img
                className="headshot--small"
                src={Headshot}
                alt="blob with sheet music inside it"
              />
            </div>

            <span className="slide__text slide__text--margin-all">
              Carah enjoys performing around the valley in both musical theatre
              and opera. Recent favorite performances include holiday caroling
              with the Goode Time Carolers, Sarah Brown in Guys and Dolls at Zao
              Theatre, featured soloist with Chandler Opera Company, Johanna in
              Sweeney Todd at MCC and Kate in Pirates of Penzance at MET. When
              she's not teaching or performing, she enjoys swing dancing with
              her husband Jason, crafting, and playing with their three kitties
              at home.
            </span>
          </div>

          <span className="about-slide__header about-slide__header slide__text--medium slide__text--title-font">
            Teaching & Schooling
          </span>
          <div className="slide__detail slide__text--bio">
            <span className="bio-experience">
              <div className="bio-logo-wrapper">
                <div className="bio-logo-img-wrapper">
                  <img
                    className="bio-logo bio-logo--85"
                    src={Studio3Logo}
                    alt="Studio3 Logo"
                  />
                </div>
                <span className="bio-logo-text">2019 - Present</span>
                <span>Private Voice, Musical Theatre, Acting</span>
              </div>
              <div className="bio-logo-wrapper">
                <div className="bio-logo-img-wrapper">
                  <img
                    className="bio-logo bio-logo--150"
                    src={VcsLogo}
                    alt="Valley Christian Logo"
                  />
                </div>
                <span className="bio-logo-text">2019 - 2022</span>
                <span>Choir, Worship, Theatre and Art</span>
              </div>
              <div className="bio-logo-wrapper">
                <div className="bio-logo-img-wrapper">
                  <img
                    className="bio-logo bio-logo--85"
                    src={GcuLogo}
                    alt="GCU Logo"
                  />
                </div>
                <span className="bio-logo-text">2015 - 2018</span>
                <span>B.A. Music with an Emphasis in Voice</span>
              </div>
            </span>
          </div>

          <span className="about-slide__header about-slide__header slide__text--medium slide__text--title-font">
            Recent Performances
          </span>
          <div className="slide__detail slide__text--bio">
            <span className="bio-experience">
              <div className="bio-logo-wrapper">
                <div className="bio-logo-img-wrapper">
                  <img
                    className="bio-logo bio-logo--85"
                    src={ZaoLogo}
                    alt="Zao Theatre Logo"
                  />
                </div>
                <span className="bio-logo-text">2022</span>
                <span>Sarah Brown - Guys and Dolls</span>
              </div>
              <div className="bio-logo-wrapper">
                <div className="bio-logo-img-wrapper">
                  <img
                    className="bio-logo bio-logo--85 bio-logo--round"
                    src={ChandlerOperaLogo}
                    alt="ChandlerOpera Logo"
                  />
                </div>
                <span className="bio-logo-text">2022</span>
                <span>Featured Soloist</span>
              </div>
              <div className="bio-logo-wrapper">
                <div className="bio-logo-img-wrapper">
                  <img
                    className="bio-logo bio-logo--150"
                    src={MccLogo}
                    alt="Mesa Community College Logo"
                  />
                </div>
                <span className="bio-logo-text">2022</span>
                <span>Johanna - Sweeney Todd</span>
              </div>
              <div className="bio-logo-wrapper">
                <div className="bio-logo-img-wrapper">
                  <img
                    className="bio-logo bio-logo--gtc"
                    src={GoodeTimeLogo}
                    alt="Goode Time Charolers Logo"
                  />
                </div>
                <span className="bio-logo-text">2018 - Present</span>
                <span>Goode Time Caroler - Soprano</span>
              </div>
              <div className="bio-logo-wrapper">
                <div className="bio-logo-img-wrapper">
                  <img
                    className="bio-logo bio-logo--met"
                    src={MetLogo}
                    alt="Mesa Encore Theatre Logo"
                  />
                </div>
                <span className="bio-logo-text">2021</span>
                <span>Kate - Pirates of Penzance</span>
              </div>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Bio

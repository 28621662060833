import { Link } from 'react-router-dom'

import Headshot from '../assets/images/headshot.png'
import WhatsAppIcon from '../assets/icons/whatsapp.svg'
import FacebookIcon from '../assets/icons/facebook.svg'
import MailIcon from '../assets/icons/mail.svg'

const AboutMe = () => {
  return (
    <div id="slide-about-me" className="about-me slide">
      <div className="slide__header">About Carah</div>
      <div className="slide__sections">
        <div className="slide__section slide__section">
          <div className="headshot-wrapper">
            <div className="headshot-detail">
              <span className="about-slide__header about-slide__header--no-line slide__text--medium slide__text--title-font">
                What is your story? What inspires you to sing?
              </span>

              <div className="slide__detail">
                <span className="slide__text slide__text--margin-all">
                  I've always loved the idea of becoming a character onstage -
                  getting to sing, dance, and act as someone other than myself.
                  I felt myself come alive onstage. For me, singing has become a
                  vessel through which I can communicate the story that is being
                  told onstage.
                </span>
              </div>

              <span className="about-slide__header about-slide__header--no-line slide__text--medium slide__text--title-font">
                What was the moment when you knew YOU wanted to sing?
              </span>

              <div className="slide__detail">
                <span className="slide__text slide__text--margin-all">
                  I grew up taking piano lessons in early Elementary school.
                  However, the thought of being on stage terrified me. Slowly, I
                  became more comfortable with having more of the spotlight on
                  me.
                </span>
                <span className="slide__text slide__text--margin-all">
                  It all started back at a church music camp that we would do
                  every summer. Every year my participation onstage grew and
                  then in my final year of the camp - 6th grade - I landed the
                  lead role! It took a lot out of me to get to that point, but
                  after I did, I realized that I was made for this!
                </span>
                <span className="slide__text slide__text--margin-all">
                  In high school, I started taking voice lessons. It was there
                  that I discovered opera and that my voice could do crazy
                  things I never thought possible!
                </span>
              </div>
            </div>

            <div className="headshot-img-wrapper">
              <Link to="/about-me/bio" className="headshot-bio-wrapper">
                <img
                  className="headshot"
                  src={Headshot}
                  alt="blob with sheet music inside it"
                />
                <div className="headshot-bio-link">View Bio</div>
              </Link>

              <div className="social-links-wrapper">
                <a href="https://wa.me/14808153372" target="_blank">
                  <img
                    className="social-image"
                    src={WhatsAppIcon}
                    alt="whatsapp icon"
                  />
                </a>
                <a href="http://m.me/carah.robenalt" target="_blank">
                  <img
                    className="social-image"
                    src={FacebookIcon}
                    alt="facebook icon"
                  />
                </a>
                <a href="mailto:carahmollerup@gmail.com" target="_blank">
                  <img
                    className="social-image"
                    src={MailIcon}
                    alt="email icon"
                  />
                </a>
              </div>
            </div>
          </div>

          <span className="about-slide__header about-slide__header--no-line slide__text--medium slide__text--title-font">
            What challenges you, discourages you, motivates you to keep going?
          </span>

          <div className="slide__detail">
            <span className="slide__text slide__text--margin-all">
              By the end of high school, I knew that I wanted to go to college
              to continue to grow my performance abilities. Dancing was my
              strong suit, but I knew I needed to work on my weakest parts to
              become a triple-threat. So, I landed on a Vocal Performance Major!
            </span>

            <span className="slide__text slide__text--margin-all">
              Throughout my time in college and as a graduate, I have been faced
              with many disappointments. Opportunities I wished I had, roles I
              didn't get, offers that didn't pull through… yet, what gets me
              going again is the excitement of “what's next.” There are always
              so many opportunities, you just have to look hard sometimes to
              pursue them. I believe each of us have been given a gift, and that
              there is a purpose we have in sharing that with others - to
              encourage or inspire them in some way. I want to continue doing
              that as long as I can.
            </span>
          </div>

          <span className="about-slide__header about-slide__header--no-line slide__text--medium slide__text--title-font">
            Let me come alongside you in your singing journey.
          </span>

          <div className="slide__detail">
            <span className="slide__text slide__text--margin-all">
              Let's help you find joy in singing again. I want to help you
              overcome any fears or inhibitions that hold you back from leaping
              towards your goals. Whether you are an aspiring singer or seasoned
              performer, let's meet to create an individualized plan fit just
              for you.
            </span>
          </div>
        </div>
        {/* <!-- <div className="slide__section">
              <div className="slide__center-wrapper">
                <img
                  className="headshot"
                  src={}
                  alt="blob with sheet music inside it"
                />
              </div>
            </div> --> */}
      </div>
    </div>
  )
}

export default AboutMe

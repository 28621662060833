import axios from 'axios'

export default function fetchCalendarData() {
  const config = {
    method: 'get',
    url: '/.netlify/functions/fetch-calendar-data',
    headers: {}
  }

  return axios(config)
}

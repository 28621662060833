import { saveAs } from 'file-saver'
import recitalImage from '../assets/images/recital/fall-2023-flyer.png'
import { Link } from 'react-router-dom'

const Recital = () => {
  const downloadImage = () => {
    saveAs(recitalImage, 'winter-2023-recital-flyer.jpg') // Put your image url here.
  }

  return (
    <div className="recital">
      <div className="flyer__header">
        <div className="flyer__header-title">Winter 2023 Recital!</div>
        <Link className="flyer__header-link" to={'/home'}>
          ← Back To Carah Mollerup Home
        </Link>
      </div>
      <img className="flyer__image" src={recitalImage} alt="Recital Flyer" />
      <button className="flyer__button" onClick={downloadImage}>
        <div className="flyer__button-inner">Save Flyer</div>
      </button>
    </div>
  )
}

export default Recital

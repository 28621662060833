import { useEffect, useState } from 'react'
import closeIcon from '../assets/icons/circle-xmark-solid.svg'
import { Link } from 'react-router-dom'

const Notice = ({ path }) => {
  const [transform, setTransform] = useState('translate(0%, -100%)')
  const [closed, setClosed] = useState(false)

  const handleClose = () => {
    setTimeout(() => setTransform('translate(0%, -100%)'), 0)
    setClosed(true)
  }

  useEffect(() => {
    if (!closed) {
      setTimeout(() => setTransform('translate(0%, 0%)'), 1500)
    }
  }, [path])

  return (
    <div id="esa-notice" className="esa-margin-top" style={{ transform }}>
      <span>
        <Link className="esa-learn-more" to="/esa-funds">
          ESA Funds
        </Link>
        <span> </span>
        Accepted!
      </span>
      <div id="close" onClick={handleClose}>
        <img className="esa-close" src={closeIcon} />
      </div>
    </div>
  )
}

export default Notice

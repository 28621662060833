import { BrowserRouter, Routes, Route } from 'react-router-dom'
import PageWrapper from './pages/PageWrapper/PageWrapper'
import Home from './pages/Home'
import Pricing from './pages/Pricing'
import Schedule from './pages/Schedule'
import StudioInfo from './pages/StudioInfo'
import AboutMe from './pages/AboutMe'
import Bio from './pages/Bio'
import Success from './pages/ConsultForm/Success'
import Failure from './pages/ConsultForm/Failure'
import './assets/css/main.css'
import Availability from './pages/Availability'
import ESAFunds from './pages/ESAFunds'
import Recital from './pages/Recital'

const pages = [
  { path: '/', page: <Home /> },
  { path: '/home', page: <Home /> },
  { path: '/pricing', page: <Pricing /> },
  { path: '/schedule', page: <Schedule /> },
  { path: '/studio-info', page: <StudioInfo /> },
  { path: '/about-me', page: <AboutMe /> },
  { path: '/about-me/bio', page: <Bio /> },
  { path: '/consult-form/success', page: <Success /> },
  { path: '/consult-form/failure', page: <Failure /> },
  { path: '/availability', page: <Availability /> },
  { path: '/esa-funds', page: <ESAFunds /> },
  { path: '/recital', page: <Recital /> }
]

const excludeWrapper = ['/recital']

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {pages.map(({ path, page }) => {
          if (excludeWrapper.includes(path)) {
            return <Route key={path} path={path} element={page} />
          } else {
            return (
              <Route key={path} path={path} element={PageWrapper(page, path)} />
            )
          }
        })}
      </Routes>
    </BrowserRouter>
  )
}

export default App

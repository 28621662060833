import { useNavigate } from 'react-router-dom'

const API_ID =
  'AKfycbwFNTOz8HIedcQi3Oe8Exm3wNiZgDRgNJIkixMfalq_vSn8w9MpwVQobTE8TbH7pLPz'
const CALENDAR_API = `https://script.google.com/macros/s/${API_ID}/exec`

const ConsultForm = () => {
  const navigate = useNavigate()

  const setConsult = () => {
    if (validateInput('all')) {
      return
    }

    handlePending()

    const body = {
      name: document.getElementById('name').value,
      phone: document.getElementById('phone').value,
      email: document.getElementById('email').value,
      message: document.getElementById('questions').value
    }

    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/x-www-form-urlencoded')

    const urlencoded = new URLSearchParams()
    for (const key in body) {
      urlencoded.append(key, body[key])
    }

    const requestOptions = {
      method: 'POST',
      mode: 'no-cors',
      credentials: 'include',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    }

    fetch(CALENDAR_API, requestOptions)
      .then(response => response.text())
      .then(_result =>
        setTimeout(() => {
          clearAndEnableForm()
          navigate('/consult-form/success')
        }, 2000)
      )
      .catch(_error =>
        setTimeout(() => {
          clearAndEnableForm()
          navigate('/consult-form/error')
        }, 2000)
      )
  }

  const handlePending = () => {
    const name = document.getElementById('name')
    name.disabled = true

    const phone = document.getElementById('phone')
    phone.disabled = true

    const email = document.getElementById('email')
    email.disabled = true

    const questions = document.getElementById('questions')
    questions.disabled = true

    const submit = document.getElementById('submitConsult')
    submit.disabled = true
    submit.classList.add('schedule-button--disabled')
    document.getElementById('submitEllipsis').classList.add('submit-show')
    document.getElementById('submitText').classList.add('submit-hide')
  }

  const clearAndEnableForm = () => {
    const name = document.getElementById('name')
    name.disabled = false
    name.value = ''

    const phone = document.getElementById('phone')
    phone.disabled = false
    phone.value = ''

    const email = document.getElementById('email')
    email.disabled = false
    email.value = ''

    const questions = document.getElementById('questions')
    questions.disabled = false
    questions.value = ''

    const submit = document.getElementById('submitConsult')
    submit.disabled = false
    submit.classList.remove('schedule-button--disabled')

    document.getElementById('submitEllipsis').classList.remove('submit-show')
    document.getElementById('submitText').classList.remove('submit-hide')
  }

  const validateInput = type => {
    let invalidInput = false
    if (type === 'name' || type === 'all') {
      const nameElement = document.getElementById('name')
      const nameInvalid = document.getElementById('nameInvalid')
      const name = nameElement.value
      if (!name || name === '') {
        nameElement.classList.add('input-invalid')
        nameInvalid.classList.remove('input-required-text--hide')
        invalidInput = true
      } else {
        nameElement.classList.remove('input-invalid')
        nameInvalid.classList.add('input-required-text--hide')
      }
    }
    if (type === 'phone' || type === 'all') {
      const phoneElement = document.getElementById('phone')
      const phoneInvalid = document.getElementById('phoneInvalid')
      const phone = phoneElement.value
      const phoneRegex = new RegExp(
        /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
      )
      if (phoneRegex.test(phone)) {
        phoneElement.classList.remove('input-invalid')
        phoneInvalid.classList.add('input-required-text--hide')
      } else {
        phoneElement.classList.add('input-invalid')
        phoneInvalid.classList.remove('input-required-text--hide')
        invalidInput = true
      }
    }
    if (type === 'email' || type === 'all') {
      const emailElement = document.getElementById('email')
      const emailInvalid = document.getElementById('emailInvalid')
      const email = emailElement.value
      const emailRegex = new RegExp(/\S+@\S+\.\S+/)
      if (emailRegex.test(email)) {
        emailElement.classList.remove('input-invalid')
        emailInvalid.classList.add('input-required-text--hide')
      } else {
        emailElement.classList.add('input-invalid')
        emailInvalid.classList.remove('input-required-text--hide')
        invalidInput = true
      }
    }
    if (type === 'all') {
      return invalidInput
    }
  }

  return (
    <>
      <div className="contact-form__row">
        <div className="contact-form__row-item">
          <label
            className="contact-form__label contact-form__label--padding"
            htmlFor="name"
          >
            Name <span className="input-required">*</span>
            <span
              id="nameInvalid"
              className="input-required-text input-required-text--hide"
            >
              name is required
            </span>
          </label>
          <input
            id="name"
            className="contact-form__input contact-form__input--phone contact-form__input-row"
            name="name"
            onChange={() => validateInput('name')}
            required
          />
        </div>
        <div className="contact-form__row-item">
          <label
            className="contact-form__label contact-form__label--padding"
            htmlFor="phone"
          >
            Phone Number <span className="input-required">*</span>
            <span
              id="phoneInvalid"
              className="input-required-text input-required-text--hide"
            >
              invalid
            </span>
          </label>
          <input
            id="phone"
            className="contact-form__input contact-form__input--phone contact-form__input-row"
            name="phone"
            onChange={() => validateInput('phone')}
            required
          />
        </div>
      </div>

      <label
        className="contact-form__label contact-form__label--padding"
        htmlFor="email"
      >
        Email <span className="input-required">*</span>
        <span
          id="emailInvalid"
          className="input-required-text input-required-text--hide"
        >
          valid email is required
        </span>
      </label>
      <input
        id="email"
        className="contact-form__input contact-form__input--phone"
        name="email"
        onChange={() => validateInput('email')}
        required
      />

      <label
        className="contact-form__label contact-form__label--padding"
        htmlFor="questions"
      >
        Any specific questions you'd like to chat about?
      </label>
      <textarea
        id="questions"
        className="contact-form__input contact-form__input--text-area"
        name="questions"
      ></textarea>

      <button
        id="submitConsult"
        className="schedule-button schedule-button--green schedule-button--full-width"
        onClick={setConsult}
      >
        <span id="submitText" className="schedule-button-text">
          Submit
        </span>
        <div id="submitEllipsis" className="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </button>
    </>
  )
}

export default ConsultForm

const ESAFunds = () => {
  return (
    <div id="slide-esa" className="esa slide">
      <div className="slide__header">ESA Funds</div>
      <div className="slide__sections">
        <div className="slide__section">
          <span className="about-slide__header about-slide__header--no-line slide__text--medium slide__text--title-font">
            What is ESA? (Empowerment Scholarship Account Program)
          </span>
          <div className="slide__detail">
            <ul>
              <li className="slide__text slide__text--margin">
                Expands educational opportunities for eligible students outside
                of the public school system
              </li>
              <li className="slide__text slide__text--margin">
                Provides public funding that can be used for a wide variety of
                educational expenses
              </li>
              <li className="slide__text slide__text--margin">
                Pays for private school tuition, educational therapies,
                tutoring, and more
              </li>
            </ul>
          </div>
          <span className="about-slide__header about-slide__header--no-line slide__text--medium slide__text--title-font">
            Does my child qualify for ESA?
          </span>
          <div className="slide__detail">
            <ul>
              <li className="slide__text slide__text--margin">
                The Arizona Department of Education provides eligibility
                requirements and application on their website{' '}
                <a
                  className="esa-blue"
                  href="https://www.azed.gov/esa/eligibility-requirements"
                  target="_blank"
                >
                  https://www.azed.gov/esa/eligibility-requirements
                </a>
              </li>
            </ul>
          </div>
          <span className="about-slide__header about-slide__header--no-line slide__text--medium slide__text--title-font">
            How can I pay for lessons with ESA funds?
          </span>
          <div className="slide__detail">
            <ul>
              <li className="slide__text slide__text--margin">
                As a certified ClassWallet vendor I can accept ESA funds through
                ClassWallet.
              </li>
              <li className="slide__text slide__text--margin">
                You can find more information on ClassWallet by visiting{' '}
                <a
                  className="esa-blue"
                  href="https://www.azed.gov/esa/classwallet"
                  target="_blank"
                >
                  https://www.azed.gov/esa/classwallet
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ESAFunds

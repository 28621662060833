const StudioInfo = () => {
  return (
    <div id="slide-studio-info" className="studio-info slide">
      <div className="slide__header">Studio Info</div>
      <div className="slide__sections">
        <div className="slide__section slide__section">
          {/* <!-- about the studio --> */}
          <span className="about-slide__header slide__text--medium slide__text--title-font">
            About the Studio
          </span>
          <div className="slide__detail">
            <span className="slide__text slide__text--margin">
              In this studio, you will get personalized training where we will
              work on the following:
            </span>
          </div>
          <div className="slide__detail about-wrapper">
            <div className="about-wrapper__column about-wrapper__column--margin">
              <span className="slide__text slide__text--title-font slide__text--medium-ish slide__text--margin">
                Vocal Lessons
              </span>
              <ul className="studio-info__list">
                <li className="slide__text slide__text--margin">
                  Developing and maintaining your vocal health
                </li>
                <li className="slide__text slide__text--margin">
                  Finding your true, authentic voice
                </li>
                <li className="slide__text slide__text--margin">
                  Adapting your voice for different styles
                </li>
                <li className="slide__text slide__text--margin">
                  Preparing for an expressive performance
                </li>
                <li className="slide__text slide__text--margin">
                  Sight reading and music theory
                </li>
              </ul>
            </div>
            <div className="about-wrapper-column">
              <span className="slide__text slide__text--title-font slide__text--medium-ish slide__text--margin">
                Piano Lessons
              </span>
              <ul className="studio-info__list">
                <li className="slide__text slide__text--margin">
                  Rhythm and note reading
                </li>
                <li className="slide__text slide__text--margin">
                  Developing flexibility and strength
                </li>
                <li className="slide__text slide__text--margin">
                  Introduction to music theory
                </li>
                <li className="slide__text slide__text--margin">
                  Increasing technique and confidence for solo repertoire
                </li>
              </ul>
            </div>
          </div>
          {/* <!-- teaching philosophy --> */}
          <span className="about-slide__header slide__text--medium slide__text--title-font">
            Teaching Philosophy
          </span>
          <div className="slide__detail">
            <span className="slide__text slide__text--margin">
              We were all born with a sense to create. I want to equip you with
              the tools you need to expand your creativity.
            </span>
            <span className="slide__text slide__text--margin">
              Creativity must be cultivated through regular practice and hard
              work - both during lessons and at home. You are your own biggest
              motivator.
            </span>
            <span className="slide__text slide__text--margin">
              Self-awareness and reflection are encouraged throughout the
              lessons for optimal retention. Students are prompted to make
              physical and auditory observations during their lessons so that
              they can replicate the same techniques during practice at home.
            </span>
            <span className="slide__text slide__text--margin">
              <b>Principal styles taught:</b> Classical, Broadway, Contemporary
              Christian, Folk
            </span>
            <span className="slide__text slide__text--margin">
              <b>Basic techniques taught:</b> Classical, musical theater/belt
            </span>
          </div>
          {/* <!-- additional info --> */}
          <span className="about-slide__header slide__text--medium slide__text--title-font">
            Additional Studio Information
          </span>
          <div className="slide__detail">
            <span className="slide__text slide__text--margin">
              Lessons take place in my home studio in Chandler, AZ, where I have
              3 cats
            </span>
            <span className="slide__text slide__text--margin">
              Parents are requested to attend the first lesson for a 15 minute
              meeting, after that they are free to stay or head out
            </span>
            <span className="slide__text slide__text--margin">
              Online lessons available upon request, via Zoom
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StudioInfo

import { Link } from 'react-router-dom'
import ConsultForm from './ConsultForm/ConsultForm'

import MessageIcon from '../assets/icons/message.svg'
import WhatsAppIcon from '../assets/icons/whatsapp.svg'
import FacebookIcon from '../assets/icons/facebook.svg'
import EmailIcon from '../assets/icons/mail.svg'

const Schedule = () => {
  return (
    <div id="slide-schedule" className="schedule slide">
      <div className="slide__header">Schedule</div>
      <div className="slide__sections">
        <div className="slide__section slide__section--no-center slide__section-full-width">
          <span className="about-slide__header slide__text--medium slide__text--title-font">
            Book A Free Phone Consultation
          </span>
          <ConsultForm />
        </div>
        <div className="slide__section slide__section-full-width">
          <span className="slide__text slide__text--availability">
            Looking for available lesson times?
            <br />
            Visit<span> </span>
            <Link to="/availability" className="availability-link">
              Availability
            </Link>
          </span>
          <img
            className="social-message-image"
            src={MessageIcon}
            alt="message bubble icon"
          />
          <div className="social-links-text">Social Media Connects</div>
          <div className="social-links-wrapper">
            <a href="https://wa.me/14802973519" target="_blank">
              <img
                className="social-image"
                src={WhatsAppIcon}
                alt="whatsapp icon"
              />
            </a>
            <a href="http://m.me/carah.robenalt" target="_blank">
              <img
                className="social-image"
                src={FacebookIcon}
                alt="facebook icon"
              />
            </a>
            <a href="mailto:carahmollerup@gmail.com" target="_blank">
              <img className="social-image" src={EmailIcon} alt="email icon" />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Schedule

import CloseIcon from '../../assets/icons/close.svg'
import NavIcon from '../../assets/icons/nav.svg'
import MainWebp from '../../assets/backgrounds/main-background.webp'
import MainPng from '../../assets/backgrounds/main-background.png'
import { Link } from 'react-router-dom'

const pages = [
  { to: '/home', text: 'Home' },
  { to: '/pricing', text: 'Pricing' },
  { to: '/schedule', text: 'Schedule' },
  { to: '/studio-info', text: 'Studio Info' },
  {
    to: '/about-me',
    text: 'About Me'
    // list: [
    //   {
    //     to: '/about-me/bio',
    //     text: 'Bio'
    //   }
    // ]
  }
]

const Nav = () => {
  const toggleModal = () => {
    const transition = 'nav-modal--transition'
    const transitionBack = 'nav-modal-back--transition'
    const open = 'nav-modal--open'
    const openBack = 'nav-modal-back--open'
    const modal = document.getElementById('nav-modal')
    const modalBack = document.getElementById('nav-modal-back')

    modal.classList.toggle(transition)
    modalBack.classList.toggle(transitionBack)

    setTimeout(() => {
      modal.classList.toggle(open)
      modalBack.classList.toggle(openBack)
    }, 1)

    setTimeout(() => {
      modal.classList.toggle(transition)
      modalBack.classList.toggle(transitionBack)
    }, 501)
  }

  const path = window.location.pathname

  const navItems = pages.map(({ to, text, list }) => {
    return (
      <Link key={to} to={to} className="nav-underline">
        <span className={`nav-item ${to === path ? 'nav-item--active' : ''}`}>
          {text}
        </span>
        {list && (
          <ul className="dropdown">
            {list.map((listItem, index) => {
              return (
                <li className="dropdown-item">
                  <Link
                    className="dropdown-item__text"
                    key={listItem.to + index}
                    to={listItem.to}
                  >
                    {listItem.text}
                  </Link>
                </li>
              )
            })}
          </ul>
        )}
      </Link>
    )
  })

  const mobileNavItems = pages.map(({ to, text }) => {
    return (
      <Link key={to} to={to} className="nav-underline">
        <span
          className={`nav-item ${to === path ? 'nav-item--active' : ''}`}
          onClick={toggleModal}
        >
          {text}
        </span>
      </Link>
    )
  })

  return (
    <>
      <div id="nav-modal-back" className="nav-modal-back"></div>
      <div id="nav-modal" className="nav-modal">
        <img
          className="nav-modal__close"
          src={CloseIcon}
          onClick={toggleModal}
          alt="close"
        />
        <div className="nav-items nav-items--modal">{mobileNavItems}</div>
      </div>
      <picture>
        <source type="image/webp" srcSet={MainWebp} />
        <source type="image/png" srcSet={MainPng} />
        <img className="landing-page-image" src={MainPng} alt="background" />
      </picture>
      <div className="nav-wrapper">
        <div className="nav">
          <span className="nav-title">
            <img
              className="nav-title__nav"
              src={NavIcon}
              alt="three bars"
              onClick={toggleModal}
            />
            <span>
              <span>Carah Mollerup's </span>
              <span className="nav-title--color">Voice Studio</span>
            </span>
          </span>
          <div className="nav-items">{navItems}</div>
        </div>
      </div>
    </>
  )
}

export default Nav

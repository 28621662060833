import Nav from './Nav'

const tallSlides = [
  '/studio-info',
  '/about-me',
  '/about-me/bio',
  '/consult-form',
  '/recital'
]

const PageWrapper = (Page, path) => {
  return (
    <>
      <Nav />
      <div
        id="slides"
        style={{}}
        className={`slides ${
          tallSlides.includes(path) ? 'slides--tall' : null
        }`}
      >
        <div id="slide-wrapper" className="slide-wrapper">
          {Page}
        </div>
      </div>
    </>
  )
}

export default PageWrapper

import Notice from './Notice'
import HeroImage from '../assets/backgrounds/home-image-background.svg'
import { Link } from 'react-router-dom'

const Home = () => {
  return (
    <div id="slide-home" className="home slide">
      <div className="slide__header">Home</div>
      <div className="slide__sections slide__sections--no-padding-bottom">
        <Notice path="/home" />
        <span className="slide__text--normal slide__text--title-font">
          Offering personalized vocal training for singers of all skill levels
          and piano lessons for the beginner.
        </span>
      </div>
      <div className="slide__sections slide__sections--no-padding slide__sections--no-margin">
        <span className="slide__text--normal slide__text--title-font">
          Lessons in Chandler, AZ and online.
        </span>
      </div>
      <div className="slide__sections slide__sections--no-margin">
        <div className="slide__section">
          <span className="about-slide__header about-slide__header--no-line slide__text--medium slide__text--title-font">
            Do you long to sing with freedom?
          </span>
          <div className="slide__detail">
            <span className="slide__text slide__text--italic-title">
              Perhaps this is you...
            </span>
            <ul>
              <li className="slide__text slide__text--margin">
                You <i>know</i> you have natural talent, but want to reach your
                full potential.
              </li>
              <li className="slide__text slide__text--margin">
                You love to sing at home alone, but the idea of performing for
                an audience terrifies you.
              </li>
              <li className="slide__text slide__text--margin">
                You've been singing for years, but aren't sure what your next
                steps are.
              </li>
            </ul>
          </div>
          <span className="about-slide__header about-slide__header--no-line slide__text--medium slide__text--title-font">
            Let's discover how to...
          </span>
          <div className="slide__detail">
            <ul>
              <li className="slide__text slide__text--margin">
                fully express yourself through the music
              </li>
              <li className="slide__text slide__text--margin">
                be present when you perform and sing with freedom
              </li>
              <li className="slide__text slide__text--margin">
                develop your own, unique sound!
              </li>
            </ul>
          </div>
          <div className="slide__center-wrapper">
            <Link to="/schedule" className="schedule-button desktop">
              Schedule Now
            </Link>
          </div>
        </div>
        <div className="slide__section">
          <div className="slide__center-wrapper column align-center">
            <img
              className="hero-image"
              src={HeroImage}
              alt="blob with sheet music inside it"
            />

            <div className="testimonial">
              <span>
                "We have been so pleased with our son's voice lessons with
                Carah. She is an incredibly talented and encouraging
                instructor."
              </span>
              {/* <!-- <br /> -->
                  <!-- <span>
                    She is very willing to customize the lesson to your
                    individual child's musical style. My son is so happy to be a
                    part of choosing which songs he gets to work on. This
                    involvement also helps when it comes to him maintaining a
                    practice schedule at home. My son has been learning new tips
                    and tricks to improve his skills from the very first
                    lesson….we have been so impressed with Carah's knowledge. My
                    son looks forward to lesson time with Carah each week"
                  </span> --> */}
              <br />
              <br />
              <span>- KATE VANEYK</span>
            </div>
            <Link to="/schedule" className="schedule-button mobile">
              Schedule Now
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home
